<template>
    <unidade-form operacao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></unidade-form>
</template>

<script>
import UnidadesServices from './services';
import UnidadeForm from './UnidadeForm';

export default {
    components: {
        UnidadeForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Expressos_Unidades' });
        },

        inserir(unidade) {
            this.$store.dispatch('addRequest');
            UnidadesServices.inserir(unidade).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Unidade de Atendimento',
                        detail: 'Unidade de Atendimento inserida com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(unidadeParametroId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Expressos_Unidades_Detalhar',
                params: {
                    id: unidadeParametroId,
                },
            });
        },
    },
};
</script>
